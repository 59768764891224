
import {computed, defineComponent, ref} from "vue";
import {Component} from "@/domain/model/material/material.model";
import {IonBadge, IonCol, IonItem, IonLabel, isPlatform} from "@ionic/vue";
import {Order, ProducedOrder} from "@/domain/model/order/order.model";
import {useStore} from "vuex";
import {Stock} from "@/domain/model/stock/stock.model";
import _ from "lodash";
import {useStock} from "@/domain/service/model/stock.service";

export default defineComponent({
  name: "DetailsOrderComponent",
  components: {
    IonCol,
    IonItem,
    IonLabel,
    IonBadge,
  },
  props: {
    orderComponent: null,
    order: null,
    type: null,
  },
  setup(prop: any) {
    const store = useStore();
    const component = ref<Component>(prop.orderComponent);
    const orderRef = ref<Order>(prop.order);
    const typeProp = ref<any>(prop.type);
    const {calculateBlockStock} = useStock()
    const amountNeeded = computed(() => {
      return component.value.amount * orderRef.value.expectedAmount;
    });
    const factoryStock = computed<Stock>(() => store.getters.lastStockByRef(component.value.ref));

    const blockStock = computed<number>(() => {
      // return store.getters.blockStockByRef(component.value.ref);
      if (typeProp.value === 'active')
        return calculateBlockStock(store.getters.allActiveOrders, component.value.ref);
      else
        return calculateBlockStock(store.getters.allPendingAndActiveOrders, component.value.ref);
    });

    const availableStock = computed<number>(() => {
      return Number((Number(factoryStock.value.amount) - Number(blockStock.value)).toFixed(2));
    });

    const actualStatus = computed<boolean>(() => availableStock.value >= 0);

    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    return {
      isTablet,
      component,
      orderRef,
      amountNeeded,
      factoryStock,
      blockStock,
      availableStock,
      actualStatus,
    };
  }
})


import {computed, defineComponent, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {
  IonBackButton, IonButtons,
  IonCard, IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle, IonToolbar, isPlatform, onIonViewWillEnter,
} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import DetailsOrderComponent from "@/app/view/order/details/DetailsOrderComponent.vue";
import DetailsOrderProducedComponent from "@/app/view/order/details/DetailsOrderProducedComponent.vue";
import _ from "lodash";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {useMaterialApi} from "@/domain/service/api/material.api";

export default defineComponent({
  name: "detailsOrder",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonGrid,
    IonCard,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonCardContent,
    DetailsOrderComponent,
    DetailsOrderProducedComponent,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const id = ref(route.params.id);
    const type = ref(route.params.type);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    // const order = computed<Order | undefined>(() => {
    //   return store.getters.orderById(id.value);
    // });

    const order = computed<Order | undefined>(() => {
      const order: Order = _.cloneDeep(store.getters.orderById(id.value));
      order.material.components = _.orderBy(order.material.components, ['ref'], ['asc']);
      return order;
    });


    const amountNeeded = (numberA: number, numberB: number) => Number(numberA * numberB);



    return {
      amountNeeded,
      order,
      type,
      isTablet,
    };
  }
})


import {computed, defineComponent} from "vue";
import {Order, ProducedOrder} from "@/domain/model/order/order.model";
import {useStore} from "vuex";
import {IonCol, IonItem, IonLabel, onIonViewWillEnter} from "@ionic/vue";

export default defineComponent({
  name: "DetailsOrderProducedComponent",
  components: {
    IonCol,
    IonItem,
    IonLabel,
  },
  props:{
    orderProduced: null,
  },
  setup(prop: any) {
    const store = useStore();
    const orderProducedRef = computed<ProducedOrder>(() => prop.orderProduced)
    const order = computed<Order>(() => store.getters.orderById(orderProducedRef.value.orderId));


    return {
      orderProducedRef,
      order,
    };
  }
})
